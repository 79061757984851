$primary-0: #d81ac3;
$primary-1: #110F1D;
$primary-2: #6E135A;
$primary-3: #59110b;
$primary-4: #510404;
$primary-5: #a400c0;
$primary-6: #df463a;
$primary-7: #3e0c66;
$primary-8: #72253d;
$primary-9: #FFA400;
$primary-10: #a07e00;
$primary-11: #caa248;
$primary-12: #5e1aa4;
$primary-13: #c9ab5d;
$primary-14: #ebb957;
$primary-15: #e8d19e;
$primary-16: #0088ff;
$primary-17: #6200EE;
$primary-18: #A01AC9;
$primary-19: #5d2a04;
$primary-20: #282c34;
$primary-21: #61dafb;
$primary-22: #004e7f;
$primary-23: #9c1f1f;
$primary-24: #d4b26473;
$primary-25: #ecc534;
$primary-26: #efd17c;
$primary-27: #ffffff;
$primary-28: #085604;
$primary-29: transparent;
$primary-30: #626262;
$primary-31: #bb1daa;
$primary-32: rgb(50,0,59);
$primary-33: #000;
$primary-34: #fff;
$secondary-0: #085604;
$secondary-1: #085604;
$secondary-2: #23093a;
$secondary-3: #2A0148;
$secondary-4: #24063d;
$secondary-5: #2b054e;
$secondary-6: #B50000;
$secondary-7: #511a74;
$secondary-8: #5f0ba7;
$secondary-9: #9850b2;
$secondary-10: #9F4ED1;
$secondary-11: #d0a94f;
$secondary-12: #38003a;
$secondary-13: #00ECFF;
$secondary-14: #ac43d8;
$secondary-15: #cba650;
$secondary-16: #ae7ac7;
$secondary-17: #a083c6;
$secondary-18: #9E81A7;
$secondary-19: #9273aa;
$secondary-20: #614275;
$secondary-21: #200a3a;
$secondary-22: #40006b;
$secondary-23: #b368ff;
$secondary-24: #caac67;
$secondary-25: #c26bde;
$secondary-26: #671d8f;
$secondary-27: #38003a;
$secondary-28: rgba(122,21,189,0.4);
$secondary-29: rgba(87,23,184,0.8);
$secondary-30: #a062d8;
$secondary-31: #1f063d;
$secondary-32: #022500;
$secondary-33: #bb0000;
$secondary-34: #730000;
$secondary-35: rgba(255,0,42,0.3);
$secondary-36: rgba(223,70,58,0.9);
$secondary-37: #ad8b4c;
$secondary-38: #edd8ae;
$secondary-39: #41fff4;
$secondary-40: #76D671;
$secondary-41: #003745;
$secondary-42: #253637;
$secondary-43: #fff;
$secondary-44: #f5f2f2;
$secondary-45: #695038;
$common-0: black;
$common-1: #ffffff;
$common-2: #202020;
$common-3: #222222;
$common-4: #333333;
$common-5: #666666;
$common-6: #ffffff;
$common-7: #989898;
$common-8: #999999;
$common-9: #A0A0A0;
$common-10: #AFABB1;
$common-11: #bbbbbb;
$common-12: #bdbdbd;
$common-13: #f4f4f4;
$common-14: #dddddd;
$common-15: #eeeeee;
$common-16: #111111;
$common-17: #aaaaaa;
$common-18: #cccccc;
$common-19: #fafafa;
$common-20: #eeefec;
$common-21: #303030;
$common-22: #d2cfcf;
$common-23: rgba(0,0,0,0.8);
$common-24: rgba(0,0,0,0.75);
$common-25: rgba(0,0,0,0.35);
$common-26: rgba(0,0,0,0.5);
$common-27: rgba(187,187,187,0.35);
$common-28: rgba(0,0,0,0.45);
$common-29: rgba(255,255,255,0.6);
$common-30: rgba(0,0,0,0);
$common-31: rgba(0,0,0,0.65);
$common-32: rgba(175,171,177,1);
$common-33: rgba(255,255,255,0.1);
$common-34: rgba(255,255,255,0.4);
$common-35: rgba(0,0,0,0.1);
$common-36: rgba(0,0,0,0.6);
$common-37: #ffffff;
$common-38: #383736;
$common-39: #3e3029;
$common-40: rgba(175,171,177,1);
$common-41: #ff343b;
$common-42: #6B3E16;
$error-0: #CB4038;
$error-1: #FF5042;
$success-0: #36C41E;
$success-1: #36FF8E;
$success-3: #085604;
$warning-0: #EDC309;
$warning-1: #FFE868;
$stroke-0: #085604;
$stroke-1: #3862AD;
$stroke-2: transparent;
$stroke-3: #1D7EBB;
$stroke-4: #D56E00;
$stroke-5: #626262;
$stroke-6: #BB1DAA;
$stroke-7: #730000;
$stroke-8: #2C167C;
$stroke-9: #7707AC;
$scrollbar-0: #38003a;
$scrollbar-1: #ffffff80;
$scrollbar-2: #ffffff;
 