@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 100;
  src:
    local('Poppins'),
    local('Poppins-Thin'),
    url('./fonts/Poppins-Thin.woff2') format('woff2');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 100;
  src:
    local('Poppins'),
    local('Poppins-ThinItalic'),
    url('./fonts/Poppins-ThinItalic.ttf') format('ttf');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 200;
  src:
    local('Poppins'),
    local('Poppins-ExtraLight'),
    url('./fonts/Poppins-ExtraLight.woff2') format('woff2');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 200;
  src:
    local('Poppins'),
    local('Poppins-ExtraLightItalic'),
    url('./fonts/Poppins-ExtraLightItalic.ttf') format('ttf');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 300;
  src:
    local('Poppins'),
    local('Poppins-Light'),
    url('./fonts/Poppins-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 300;
  src:
    local('Poppins'),
    local('Poppins-LightItalic'),
    url('./fonts/Poppins-LightItalic.ttf') format('ttf');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 400;
  src:
    local('Poppins'),
    local('Poppins-Regular'),
    url('./fonts/Poppins-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 400;
  src:
    local('Poppins'),
    local('Poppins-Italic'),
    url('./fonts/Poppins-Italic.ttf') format('ttf');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 500;
  src:
    local('Poppins'),
    local('Poppins-Medium'),
    url('./fonts/Poppins-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 500;
  src:
    local('Poppins'),
    local('Poppins-MediumItalic'),
    url('./fonts/Poppins-MediumItalic.ttf') format('ttf');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 600;
  src:
    local('Poppins'),
    local('Poppins-SemiBold'),
    url('./fonts/Poppins-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 600;
  src:
    local('Poppins'),
    local('Poppins-SemiBoldItalic'),
    url('./fonts/Poppins-SemiBoldItalic.ttf') format('ttf');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 700;
  src:
    local('Poppins'),
    local('Poppins-Bold'),
    url('./fonts/Poppins-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 700;
  src:
    local('Poppins'),
    local('Poppins-BoldItalic'),
    url('./fonts/Poppins-BoldItalic.ttf') format('ttf');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 800;
  src:
    local('Poppins'),
    local('Poppins-ExtraBold'),
    url('./fonts/Poppins-ExtraBold.woff2') format('woff2');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 800;
  src:
    local('Poppins'),
    local('Poppins-ExtraBoldItalic'),
    url('./fonts/Poppins-ExtraBoldItalic.ttf') format('ttf');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 900;
  src:
    local('Poppins'),
    local('Poppins-Black'),
    url('./fonts/Poppins-ExtraBold.woff2') format('woff2');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 900;
  src:
    local('Poppins'),
    local('Poppins-BlackItalic'),
    url('./fonts/Poppins-ExtraBoldItalic.ttf') format('ttf');
}

@font-face {
  font-family: 'dripicons-v2';
  src: url('./fonts/dripicons/dripicons-v2.eot');
  src:
    url('./fonts/dripicons/dripicons-v2.eot?#iefix') format('embedded-opentype'),
    url('./fonts/dripicons/dripicons-v2.woff') format('woff'),
    url('./fonts/dripicons/dripicons-v2.ttf') format('truetype'),
    url('./fonts/dripicons/dripicons-v2.svg#dripicons-v2') format('svg');
  font-weight: normal;
  font-style: normal;
}

